var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-container",
    { attrs: { id: "pending-filings-page" } },
    [
      _c(
        "masthead",
        {
          attrs: { title: "Filing Orders" },
          on: { changed: _vm.searchChanged },
        },
        [
          _c("span"),
          _c(
            "b-button",
            {
              staticClass: "rounded",
              attrs: { disabled: !_vm.selection.length > 0, variant: "danger" },
              on: {
                click: function ($event) {
                  return _vm.showCancelModal()
                },
              },
            },
            [_vm._v("\n      Cancel Selected\n    ")]
          ),
        ],
        1
      ),
      _c(
        "b-tabs",
        {
          model: {
            value: _vm.tabIndex,
            callback: function ($$v) {
              _vm.tabIndex = $$v
            },
            expression: "tabIndex",
          },
        },
        [
          _c(
            "b-tab",
            [
              _c("template", { slot: "title" }, [
                _vm._v("\n        Pending Orders\n      "),
              ]),
              _c(
                "ajax-table",
                {
                  ref: "filingsTable",
                  attrs: {
                    busy: _vm.isBusy,
                    "table-definition": _vm.tableDefinition,
                    "select-mode": "multi",
                    selectable: true,
                    "selectable-function": _vm.selectableFunction,
                    "empty-text": "You're all caught up!",
                  },
                  on: {
                    "update:busy": function ($event) {
                      _vm.isBusy = $event
                    },
                    "selection-changed": _vm.selectionEvent,
                    loaded: _vm.onLoad,
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "cell(company)",
                      fn: function (data) {
                        return [
                          _c("div", [_vm._v(_vm._s(data.item.company.name))]),
                        ]
                      },
                    },
                    {
                      key: "cell(jurisdiction)",
                      fn: function (data) {
                        return [
                          _c("div", [
                            _vm._v(
                              _vm._s(_vm.getFilingJurisdiction(data.item))
                            ),
                          ]),
                        ]
                      },
                    },
                    {
                      key: "cell(estimate)",
                      fn: function (data) {
                        return [
                          _c("div", [
                            _vm._v(_vm._s(_vm.formatEstimate(data.item))),
                          ]),
                        ]
                      },
                    },
                    {
                      key: "cell(type)",
                      fn: function (data) {
                        return [
                          _c("div", [
                            _c("div", [_vm._v(_vm._s(data.item.product.name))]),
                            data.item.sub_registration &&
                            data.item.sub_registration.kind === "dba"
                              ? _c("div", [
                                  _c("small", { staticClass: "text-muted" }, [
                                    _vm._v(
                                      "\n                " +
                                        _vm._s(
                                          data.item.sub_registration.name
                                        ) +
                                        "\n              "
                                    ),
                                  ]),
                                ])
                              : _vm._e(),
                          ]),
                          _vm.vipFiling(data.item)
                            ? _c("div", { staticClass: "vip-badge-group" }, [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "badge badge-warning vip-badge",
                                  },
                                  [_vm._v("\n              VIP\n            ")]
                                ),
                              ])
                            : _vm._e(),
                        ]
                      },
                    },
                    {
                      key: "cell(status)",
                      fn: function (data) {
                        return [
                          data.item.status === "awaiting-client-input"
                            ? _c(
                                "div",
                                {
                                  staticClass:
                                    "awaiting-client-input-container",
                                  on: {
                                    click: function ($event) {
                                      return _vm.navigateToORAPage()
                                    },
                                  },
                                },
                                [
                                  _c("feather-icon", {
                                    staticClass: "alert-icon",
                                    attrs: {
                                      type: "alert-triangle",
                                      width: 17,
                                      height: 17,
                                    },
                                  }),
                                  _c("div", [
                                    _vm._v(
                                      _vm._s(_vm.startCase(data.item.status))
                                    ),
                                  ]),
                                ],
                                1
                              )
                            : _c("div", [
                                _vm._v(
                                  "\n            " +
                                    _vm._s(_vm.startCase(data.item.status)) +
                                    "\n          "
                                ),
                              ]),
                        ]
                      },
                    },
                    {
                      key: "cell(actions)",
                      fn: function (data) {
                        return [
                          _c(
                            "b-button",
                            {
                              attrs: {
                                to: {
                                  name: "filingStatus",
                                  params: {
                                    orderId: data.item.order_id,
                                    orderItemId: data.item.id,
                                  },
                                },
                                variant: "link",
                                "aria-label": "View button",
                              },
                            },
                            [_vm._v("\n            View\n          ")]
                          ),
                        ]
                      },
                    },
                  ]),
                },
                [
                  _c("template", { slot: "head(status)" }, [
                    _c(
                      "div",
                      [
                        _vm._v("\n            Status\n            "),
                        _vm.hasVipFiling
                          ? [
                              _c("feather-icon", {
                                staticClass: "tooltip-icon",
                                attrs: {
                                  id: `vip-tooltip`,
                                  type: "help-circle",
                                },
                              }),
                              _c(
                                "b-tooltip",
                                {
                                  attrs: {
                                    target: `vip-tooltip`,
                                    triggers: "hover",
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n                You can make changes to filings within your VIP Subscription by going to your Services page.\n              "
                                  ),
                                ]
                              ),
                            ]
                          : _vm._e(),
                      ],
                      2
                    ),
                  ]),
                ],
                2
              ),
              _c("cancel-filings-modal", {
                attrs: { bus: _vm.bus },
                on: { completed: _vm.filingsCancelCompleted },
              }),
            ],
            2
          ),
          _c(
            "b-tab",
            [
              _c("template", { slot: "title" }, [
                _vm._v("\n        Completed Orders\n      "),
              ]),
              _c(
                "ajax-table",
                {
                  ref: "completedFilingsTable",
                  attrs: {
                    busy: _vm.isBusy,
                    "table-definition": _vm.completedTableDefinition,
                    "select-mode": "multi",
                    selectable: true,
                    "selectable-function": _vm.selectableFunction,
                    "empty-text": "No completed orders found!",
                  },
                  on: {
                    "update:busy": function ($event) {
                      _vm.isBusy = $event
                    },
                    "selection-changed": _vm.selectionEvent,
                    loaded: _vm.onLoadCompleted,
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "cell(company)",
                      fn: function (data) {
                        return [
                          _c("div", [_vm._v(_vm._s(data.item.company.name))]),
                        ]
                      },
                    },
                    {
                      key: "cell(jurisdiction)",
                      fn: function (data) {
                        return [
                          _c("div", [
                            _vm._v(
                              _vm._s(_vm.getFilingJurisdiction(data.item))
                            ),
                          ]),
                        ]
                      },
                    },
                    {
                      key: "cell(estimate)",
                      fn: function (data) {
                        return [
                          _c("div", [
                            _vm._v(_vm._s(_vm.formatEstimate(data.item))),
                          ]),
                        ]
                      },
                    },
                    {
                      key: "cell(type)",
                      fn: function (data) {
                        return [
                          _c("div", [
                            _c("div", [_vm._v(_vm._s(data.item.product.name))]),
                            data.item.sub_registration &&
                            data.item.sub_registration.kind === "dba"
                              ? _c("div", [
                                  _c("small", { staticClass: "text-muted" }, [
                                    _vm._v(
                                      "\n                " +
                                        _vm._s(
                                          data.item.sub_registration.name
                                        ) +
                                        "\n              "
                                    ),
                                  ]),
                                ])
                              : _vm._e(),
                          ]),
                          _vm.vipFiling(data.item)
                            ? _c("div", { staticClass: "vip-badge-group" }, [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "badge badge-warning vip-badge",
                                  },
                                  [_vm._v("\n              VIP\n            ")]
                                ),
                              ])
                            : _vm._e(),
                        ]
                      },
                    },
                    {
                      key: "cell(status)",
                      fn: function (data) {
                        return [
                          _c("div", [
                            _vm._v(
                              "\n            " +
                                _vm._s(_vm.startCase(data.item.status)) +
                                "\n          "
                            ),
                          ]),
                        ]
                      },
                    },
                    {
                      key: "cell(actions)",
                      fn: function (data) {
                        return [
                          _c(
                            "b-button",
                            {
                              attrs: {
                                to: {
                                  name: "filingStatus",
                                  params: {
                                    orderId: data.item.order_id,
                                    orderItemId: data.item.id,
                                  },
                                },
                                variant: "link",
                                "aria-label": "View button",
                              },
                            },
                            [_vm._v("\n            View\n          ")]
                          ),
                        ]
                      },
                    },
                  ]),
                },
                [
                  _c("template", { slot: "head(status)" }, [
                    _c(
                      "div",
                      [
                        _vm._v("\n            Status\n            "),
                        _vm.hasVipFiling
                          ? [
                              _c("feather-icon", {
                                staticClass: "tooltip-icon",
                                attrs: {
                                  id: `vip-tooltip`,
                                  type: "help-circle",
                                },
                              }),
                              _c(
                                "b-tooltip",
                                {
                                  attrs: {
                                    target: `vip-tooltip`,
                                    triggers: "hover",
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n                You can make changes to filings within your VIP Subscription by going to your Services page.\n              "
                                  ),
                                ]
                              ),
                            ]
                          : _vm._e(),
                      ],
                      2
                    ),
                  ]),
                ],
                2
              ),
              _c("cancel-filings-modal", {
                attrs: { bus: _vm.bus },
                on: { completed: _vm.filingsCancelCompleted },
              }),
            ],
            2
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }